*,
*:before,
*:after {
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
  font: inherit;
}
img,
picture,
svg,
video {
  max-width: 100%;
}
input,
select,
textarea {
  background-color: transparent;
  outline: none;
}
button {
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: 0;
}
body {
  min-height: 100vh;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
}
