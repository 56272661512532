@import './main';

.ant-btn {
  border-radius: 4px;
}

.ant-btn-primary {
  // border-color: var(--main-color) !important;
  background: var(--main-color) !important;
  box-shadow: unset !important;
}
