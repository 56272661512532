@import './main';

a {
  text-decoration: unset !important;
}

* {
  font-family: 'Inter', sans-serif;
}

body {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff !important;
  font-feature-settings: 'tnum';
}

.pointer {
  cursor: pointer;
}

.displayNone {
  display: none;
}

.ant-input-number {
  width: 100% !important;
}

.logo {
  max-width: 259px;
  max-height: 80px;
}

.header {
  padding: 24px 0;
  height: max-content;
  border-bottom: 1px solid #2a2a2a25 !important;

  .ant-menu-title-content {
    text-align: left;
    font: normal normal 600 16px/20px sans-serif;
    letter-spacing: 0px;
    color: #6b6b6b;
  }

  .ant-menu-item-active.active {
    .ant-menu-title-content {
      color: #0c4da2;
      font: normal normal bold 16px/20px sans-serif;
    }
  }

  &_content {
    text-transform: uppercase;
  }

  @media (max-width: 768px) {
    overflow-x: auto;
    padding: unset;

    &__container {
      width: unset !important;
    }

    &__button {
      margin-left: auto;
    }

    &_content {
      width: unset !important;

      .ant-menu-item-only-child:first-child {
        padding-left: unset;
      }
    }
  }

  @media (max-width: 813px) {
    &__logo {
      display: none;
    }
  }
}

.ant-layout-header,
.ant-menu,
.ant-menu-dark {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-bottom: none;
}

.ant-tabs-nav::before {
  border: none !important;
}

.ant-tabs-nav-wrap {
  border: none;
  outline: none;
  padding-top: 21.5px;
}

.ant-tabs-ink-bar {
  display: none;
}

.ant-tabs-tab {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 9px 12px;

  .ant-tabs-tab-btn {
    text-align: center;
    font: normal normal 600 14px/20px sans-serif;
    letter-spacing: 0px;
    color: #242426;
  }
}

.selected {
  color: #0c4da2;
  font: normal normal bold 16px/20px sans-serif;
}

.ant-tabs-tab-active {
  background-color: var(--main-color);

  div {
    color: #ffffff !important;
  }
}

@media (max-width: 576px) {
  .container {
    max-width: unset !important;
  }
}

@media (max-width: 991px) {
  .container {
    max-width: unset !important;
  }
}

.loader {
  margin-bottom: 8px;
  border: 4px solid #f3f3f3;
  /* Light grey */
  border-top: 4px solid var(--main-color);
  /* Blue */
  border-radius: 50%;
  text-align: center;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.noselect {
  -webkit-touch-callout: none !important;
  /* iOS Safari */
  -webkit-user-select: none !important;
  /* Safari */
  -khtml-user-select: none !important;
  /* Konqueror HTML */
  -moz-user-select: none !important;
  /* Old versions of Firefox */
  -ms-user-select: none !important;
  /* Internet Explorer/Edge */
  user-select: none !important;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.OFF_TABS_ACCREDITATION {
  .custom_tabs {
    padding-top: 2rem;

    .ant-tabs-nav {
      display: none;
    }
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slick-dots li {
  overflow: hidden;
  background: transparent;

  flex: 1;

  border-radius: 0px;
  border-radius: 50%;
  background: #ffffff25;

  margin: 0;

  width: 7px;
  height: 7px;
  min-width: 7px;
  min-height: 7px;
  max-width: 7px;
  max-height: 7px;
}

.slick-dots .slick-active {
  visibility: visible;
  width: 35px;
  max-width: 35px;
  height: 7px;

  background: #ffffff60;
  border-radius: 40px;
}

button.ant-pagination-item-link {
  display: flex !important;
  align-items: center;
  justify-content: center;

  border-color: #757575 !important;
}

li.ant-pagination-item {
  border-color: #757575 !important;
}

li.ant-pagination-item-active {
  border-color: #0e57b7 !important;
  background-color: #0e57b7 !important;
  color: #fff !important;
}

.d-flex {
  display: flex;
}

.ai-c {
  align-items: center;
}

.j-sb {
  justify-content: space-between;
}

.fw {
  flex-wrap: wrap;
}

.pdt-60 {
  padding-top: 60px;
}

.mobile-menu {
  position: fixed;
  width: 100%;
  height: 70px;
  left: 0;
  bottom: 0;
  z-index: 999;
  background: white;
}

a.menu-item {
  width: calc((100% - 85px) / 4);
  height: 70px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 0;
  justify-content: center;
  border-top: 1px solid #d9d9d9;

  &.active {
    border-top: 2px solid var(--primary-color);
  }

  .mtitle {
    width: 100%;
    text-align: center;
    color: #909090;
  }

  @media (max-width: 280px) {
    .mtitle {
      display: none;
    }
  }
}

.w-100 {
  width: 100%;
}

.text-important {
  margin-left: 5px;
}


.app-container {
  padding: 15px;

  &.login-container {
    padding: 30px 15px;
  }
}

.popup-message{
  width: 100% !important;
  padding: 0 5px;
  .ant-modal-close{
    display: none;
  }
  .ant-modal-content .ant-modal-header .ant-modal-title{
    text-align: center;
  }
  .ant-modal-content,.ant-modal-header{
    background: var(--background-color);
  }
  .ant-modal-footer{
    border-top:1px solid var(--gray-color);
    text-align:center;
    padding: 5px 10px;
  }
  .ant-modal-body {
    padding: 20px;
  }
  .btn-ok{
    background: var(--transparent-color) !important;
    border: none;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.register-container .ant-tabs-nav {
  display: none;
}

.login__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 40px;
}

.pl-title {
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  color: #222222;

  margin-bottom: 10px;
}

.policy-item {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.ant-picker {
  width: 100%;
}

.login__input,
.ant-picker,
.ant-select-selector {
  height: 48px;
  background: rgba(221, 221, 221, 0.1);
  border: 1px solid #dddddd;
  border-radius: 8px;
}

.ant-select-selector {
  height: 48px !important;

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
}

.login__forget {
  text-decoration-line: underline;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary-color);
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after{
  background-color: var(--step-line-booking);
}
.login__button.df,
.custom-df-btn {
  height: 46px;
  background: var(--primary-button-color) !important;
  // box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043) !important;
  border-radius: 8px !important;
  border: 1px solid transparent;
  width: 100%;

  &.ant-btn span {
    font-weight: 550;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
  }
}

.custom-df-btn {
  &.light {
    border: 1px solid var(--primary-button-color) !important;
    background: white !important;

    span {
      color: var(--primary-button-color) !important;
    }
  }

  &.danger {
    border: 1px solid #e42310 !important;
    background: #e42310 !important;

    span {
      color: white;
    }
  }

  &.danger-outline {
    border: 1px solid #e42310 !important;
    background: white !important;

    span {
      color: #e42310;
    }
  }

  &.green {
    border: 1px solid #00b59c !important;
    background: white !important;

    span {
      color: #00b59c;
    }
  }

  &.disabled-color {
    border: 1px solid #d9d9d9 !important;
    background: #f5f5f5 !important;

    span {
      color: #d9d9d9;
    }
  }
}

.mgbt-20 {
  margin-bottom: 20px;
}

.mgt-40 {
  margin-top: 40px;
}

.mgt-30 {
  margin-top: 30px;
}

.mgt-20 {
  margin-top: 20px;
}

.mgt-10 {
  margin-top: 10px;
}

.pd-30-15 {
  padding: 30px 15px;
}

.mgt-15 {
  margin-top: 15px;
}

.pd-15 {
  padding: 15px;
}

.bg-grey {
  background: #f9f9f9;
}

.directs {
  background: #ffffff;
  box-shadow: 1px 1px 12px rgba(144, 144, 144, 0.08);
  border-radius: 12px;

  .direct-item {
    padding: 15px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);

    .title {
      font-size: 15px;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border: none;
    }
  }

  &.ins-hs {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.mgr-10 {
  margin-right: 10px;
}

.mgr-15 {
  margin-right: 15px;
}

.ant-tabs {
  width: 100%;
}

.custom-form {
  .ant-form-item-label {
    padding: 0;

    label {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #222222;
    }
  }
}

.po-r {
  position: relative;
}

.po-a {
  position: absolute;
}

.pd-0-15 {
  padding: 0 15px;
}

.btn-stt {
  background: #e42310;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 8px;
  flex-grow: 1;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  margin-left: 10px;

  &.green {
    background-color: #00b59c;
  }
}

.swal-title {
  font-size: 21px;
}

.mgt-5 {
  margin-top: 5px;
}

.mgl-5 {
  margin-left: 5px;
}

.plate-tag {
  min-width: 60px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  border-radius: 4px;

  &.white {
    color: black;
    border: 1px solid #dddddd;
  }
}

.ntfy {
  border-bottom: 1px solid #dddddd;

  &:last-child {
    border: none;
  }
}
.ant-form-item {
  margin-bottom: 10px;
}
.ant-form-item-extra {
  color: var(--step-line-booking) !important;
}

.text-blue {
  color: #427aff;
}

.loading {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  background: hsla(0, 0%, 100%, 0.22);
}

.no-scroll {
  overflow: hidden;
}

.eyepass {
  top: 50%;
  right: 15px;
  z-index: 99;
  transform: translateY(-50%);
  font-size: 18px;
  color: grey;
}

.txss {
  width: 95%;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 20px;

  .cp {
    margin: 10px 0;
    color: #ff0000;
    font-size: 19px;
    text-align: center;
    font-weight: bold;

    span {
      font-size: 14px;
      color: black;
      font-weight: normal;
      margin-right: 5px;
    }
  }
}

.notgetrmsg {
  color: #0e57b7;
  font-style: italic;
  font-weight: bold;
  text-decoration: underline;
}

.txt-clip {
  margin-left: 5px;
  font-size: 12px;
  line-height: 12px;
  color: #0e57b7;
  text-decoration: underline;
  font-weight: normal;
}

.mg0 {
  margin: 0 !important;
}

.mobile-menu {
  .active {
    .mtitle {
      color: var(--title-color) !important;
    }

    svg {
      &.change-stroke path {
        stroke: var(--title-color) !important;
      }

      &.change-fill path {
        fill: var(--title-color) !important;
      }
    }
  }

  a {
    text-decoration: unset !important;
  }
}

.middle-item {
  width: 80px;
  height: 65px;

  .abs-item {
    width: 80px;
    height: 80px;
    left: 0;
    bottom: 15px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
  }
}

.btn-section {
	padding-top: 22px;
	.btn-item {
		width: 25%;
		text-align: center;
		cursor: pointer;
		margin-top: -22px;

		&:nth-child(n + 5) {
			margin-bottom: 0;
		}

	}

  &  {
    & svg {
      height: 77px;
      width: 60px;
    }
  }
}

.camp-item {
  width: 48%;
  margin-bottom: 10px;

  img {
    width: 100%;
    height: auto;
  }
}

.out-sidehme {
  background-color: #ffffff;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    bottom: 100%;
    top: -15px;
    left: 0;
    width: 100%;
    height: 16px;
    border-radius: 19px 19px 0px 0px;
    background-color: #ffffff;
  }
}

.attention {
  background: #ffffff;
  border-radius: 39px;
  width: 90%;
  margin: auto;
  font-size: 12px;
  height: 27px;
  padding: 0 11px;
  margin-bottom: 10px;
  border: 1px solid var(--title-color);

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    margin-right: 5px;
  }

  span {
    color: var(--attention-color) ;
    cursor: pointer;
  }
}

.mgbt-10 {
  margin-bottom: 10px;
}

.slider-empty {
  padding: 25px 0;

  svg {
    width: 100px;
  }
}

.item-log {
  padding: 10px 15px;
  border-bottom: 1px solid #dedede;
  display: flex;
  align-items: center;

  &:last-child {
    border-bottom: none;
  }

  span {
    font-size: 14px;
    color: var(--title-color);
  }
}

.w-33 {
  width: 33%;
}

.ant-input-number-lg input {
  height: 45px;
  background: #dddddd1a;
}

.opt-input-container {
  justify-content: center;
  margin: 0 auto 2rem;

  .opt-input {
    width: 2em !important;
    height: 2em;
    margin: 0.3rem;
  }

  @media (min-width: 375px) {
    .opt-input {
      width: 2.1em !important;
      height: 2.1em;
      margin: 0.4rem;
    }
  }

  @media (min-width: 768px) {
    margin: 0 auto 3rem;
    .opt-input {
      width: 3em !important;
      height: 3em;
      margin: 1rem;
    }
  }
}

.ant-space {
  display: block;
}
.my-modal{
  .ant-modal-content{
    padding: 20px 24px !important;
  }
}